// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/dasith/myblog/myblog/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-jsx": () => import("/home/dasith/myblog/myblog/src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-pages-blog-jsx": () => import("/home/dasith/myblog/myblog/src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("/home/dasith/myblog/myblog/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("/home/dasith/myblog/myblog/src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/dasith/myblog/myblog/.cache/data.json")

